<template>
  <div class="">

    
    <el-descriptions class="padding" title="退货地址" :column="3" border>
      <template slot="extra">
        <el-button type="primary" size="small" @click="edit()">修改退货地址</el-button>
      </template>
      <el-descriptions-item label="收货人">{{refundData.refund_consignee}}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{refundData.refund_mobile}}</el-descriptions-item>
      <el-descriptions-item label="联系地址">{{refundData.refund_address}}</el-descriptions-item>
    </el-descriptions>



    <!-- 修改退货地址 -->
    <el-dialog title="修改退货地址" :visible.sync="originalVisible" width="40%">
      <el-form ref="originaForm" :model="originaForm" label-width="100px">
        <el-form-item label="收货人" required>
          <el-input v-model="originaForm.refund_consignee"></el-input>
        </el-form-item>
        <el-form-item label="手机号" required>
          <el-input v-model="originaForm.refund_mobile"></el-input>
        </el-form-item>
        <el-form-item label="联系地址" required>
          <el-input v-model="originaForm.refund_address"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="originalVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit()">保存修改</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      originalVisible:false,
      originaForm:{
        refund_consignee:'',
        refund_mobile:'',
        refund_address:''
      },
      refundData:{

      },
    };
  },

  created() {
    this.getInfo();
  },
  mounted() {},
  computed: {},

  methods: {
    edit(){
      this.originalVisible = true;
      this.originaForm.refund_consignee = this.refundData.refund_consignee;
      this.originaForm.refund_mobile = this.refundData.refund_mobile;
      this.originaForm.refund_address = this.refundData.refund_address;
    },
    async getInfo(){
      const { data } = await this.$Api.refundConfigData();
      this.refundData = data;
    },
    async onSubmit(){
      
      const { code,msg } = await this.$Api.refundConfigSave(this.originaForm);
      if(code == 200){
        this.$message.success(msg);
      }else{
        this.$message.error(msg);
      }
      this.originalVisible = false;
      this.getInfo();


    }

  },
};
</script>
<style lang='scss' scoped>
</style>